import Base from "./Base";

export default class {
    constructor() { 
        this.instance = Base('offers')
    }

    getOfferPrice = (offerId) => {
        return this.instance({
            method: 'GET',
            url: `/${offerId}/price`,
        });
    }

    generateInstallments = (offerId, paymentMethodId, installmentMethod, recordId) => {
        return this.instance({
            method: 'POST',
            url: `/${offerId}/${paymentMethodId}/${installmentMethod}/installments`,
            data: {
                CODFICHA: recordId
            }
        });
    }

}
