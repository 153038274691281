export const formatCpf = (cpf) => {
    cpf = cpf.replace(/\D/g, '');
        
    if (cpf.length <= 3) {
        return cpf;
    } else if (cpf.length <= 6) {
        return cpf.slice(0, 3) + '.' + cpf.slice(3);
    } else if (cpf.length <= 9) {
        return cpf.slice(0, 3) + '.' + cpf.slice(3, 6) + '.' + cpf.slice(6);
    } else {
        return cpf.slice(0, 3) + '.' + cpf.slice(3, 6) + '.' + cpf.slice(6, 9) + '-' + cpf.slice(9, 11);
    }
}

export const cleanCpf = (cpf) => {
    return cpf ? cpf.replace(/\D/g, '') : '';
}

export const cleanCep = (cep) => {
    return cep ? cep.replace(/\D/g, '') : '';
}


export const cleanIdentity = (rg) => {
    return rg ? rg.replace(/\D/g, '') : '';
}

export const formatSelectOptions = (objectsArray, label, key) => {
    return objectsArray.map((e) => {
        return {
            label: e[label],
            key: e[key]
        }
    })
}


export const formatDate = (dataISO) => {

    if (dataISO.length != 19) {
        return dataISO;
    }
    const data = new Date(dataISO);


    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
    const ano = data.getFullYear();
    
    return `${dia}/${mes}/${ano}`;
}

export const formatCep = (cep) => {
     // Remove caracteres não numéricos
     const cepString = String(cep).replace(/\D/g, '');
     // Aplica a máscara à medida que o usuário digita
     if (cepString.length <= 5) {
         return cepString;
     } else {
         return `${cepString.slice(0, 5)}-${cepString.slice(5, 8)}`;
     }
}


export const formatPhone = (phone) => {
    let input = phone.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

    if (input.length == 0) {
        return '';
    }

    if (input.length > 11) {
        input = input.slice(0, 11); // Limita o tamanho em 11 caracteres
      }
  
      // Formatação condicional baseada no número de dígitos
      if (input.length <= 2) {
        input = `(${input}`;
      } else if (input.length <= 6) {
        input = `(${input.slice(0, 2)}) ${input.slice(2)}`;
      } else if (input.length <= 10) {
        // Formatação para número fixo (10 dígitos)
        input = `(${input.slice(0, 2)}) ${input.slice(2, 6)}-${input.slice(6)}`;
      } else {
        // Formatação para número celular (11 dígitos)
        input = `(${input.slice(0, 2)}) ${input.slice(2, 7)}-${input.slice(7)}`;
      }

    return input;
}


export const formatMoney = (value) => {

    if (!value) {
        return '';
    }
    let BRL = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
    
   return BRL.format(value);
}

export const formatIdentity = (value) => {
    // Remove caracteres não numéricos
    value = value.replace(/\D/g, '');
    
    // Aplica a formatação
    if (value.length <= 2) return value;
    if (value.length <= 5) return value.replace(/(\d{2})(\d+)/, '$1.$2');
    if (value.length <= 8) return value.replace(/(\d{2})(\d{3})(\d+)/, '$1.$2.$3');
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d+)/, '$1.$2.$3-$4');
};

export const formatNumber = (value) => {
      // Tenta converter o valor para um número
      let num = parseFloat(value);
    
      // Verifica se a conversão foi bem-sucedida (se o valor é um número)
      if (!isNaN(num)) {
          // Arredonda o número para duas casas decimais
          return num.toFixed(2);
      } else {
          // Caso o valor não seja um número válido, retorna uma mensagem de erro
          return null;
      }
}