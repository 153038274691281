export const validatePhone = (phone) => {

  phone = phone.replace(/[^\d]+/g, '');

  if (!phone) {
    return null;
  }

  let xphone = phone.toString().replace(/[ ()-]/g, "");

  // Expressão regular ajustada para 10 ou 11 dígitos
  let expregular = /^([0-9]{2})(?:[2-8][0-9]{3}[0-9]{4}|9[0-9]{4}[0-9]{4})$/;

  return expregular.test(xphone);
};



export const validateCpf = (cpf) => {

  if (!cpf) {
    return null;
  }

  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf.length < 11) {
    return null;
  }

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return false;
  }

  function calcularDigito(cpf, fator) {
    let soma = 0;
    for (let i = 0; i < fator - 1; i++) {
      soma += cpf[i] * (fator - i);
    }
    let resto = (soma * 10) % 11;
    return resto === 10 ? 0 : resto;
  }

  let digito1 = calcularDigito(cpf, 10);
  if (digito1 != cpf[9]) {
    return false;
  }

  let digito2 = calcularDigito(cpf, 11);
  if (digito2 != cpf[10]) {
    return false;
  }

  return true;
}

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const objectsEqual = (o1, o2) =>
  typeof o1 === 'object' && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length
    && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

export const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

export const validateCep = (cep) => {
  const cepPattern = /^[0-9]{5}-?[0-9]{3}$/;
  return cepPattern.test(String(cep));
};

export const validateIdentity = (rg) => {

  if (!rg) {
    return null;
  }

  // Remove caracteres não numéricos
  rg = rg.replace(/\D/g, '');

  // Verifica se o RG tem 9 dígitos
  if (rg.length !== 10) {
    return false; // RG deve ter 9 dígitos
  }

  // Calcula o dígito verificador
  const pesos = [9, 8, 7, 6, 5, 4, 3, 2];
  let soma = 0;

  for (let i = 0; i < 8; i++) {
    soma += parseInt(rg[i]) * pesos[i];
  }

  const resto = soma % 11;
  let dvCalculado = resto < 2 ? 0 : 11 - resto;

  const dvFornecido = parseInt(rg[8]);

  return dvCalculado === dvFornecido;

}