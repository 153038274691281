import { useState, useEffect } from "react";

import NextButtonComponent from "../Buttons/NextButton";
import TextInputComponent from "../Inputs/TextInput";
import PreviousButtonComponent from "../Buttons/PreviousButton";
import RecordService from "../../Services/Record";
import LocationService from "../../Services/Location";
import MetadataService from "../../Services/Metadata";
import SelectInputComponent from "../Inputs/SelectInput";
import OfferService from "../../Services/Offer";
import {
  formatDate,
  formatMoney,
  formatNumber,
  getNextMonthDate,
  notifyError,
} from "../../Utils";
import GenericButtonComponent from "../Buttons/GenericButton";
import TableComponent from "../Table";
import DateInputComponent from "../Inputs/DateInput";

const Step5 = ({ previousStep, nextStep, codRecord, setCodRecord }) => {
  const recordService = new RecordService();
  const locationService = new LocationService();
  const metadataService = new MetadataService();
  const offerService = new OfferService();

  const [isLoadingRecord, setIsLoadingRecord] = useState(true);

  const [formData, setFormData] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState({
    loading: false,
    data: null,
  });

  const [offerPrice, setOfferPrice] = useState({
    loading: false,
    data: null,
  });

  useEffect(() => {
    console.log({ offerPrice });
  }, [offerPrice]);

  useEffect(() => {
    console.log({ formData });
  }, [formData]);

  useEffect(() => {
    setPaymentMethods((prevState) => ({ ...prevState, loading: true }));
    metadataService
      .getPaymentMethods()

      .then((response) => {
        setPaymentMethods((prevState) => ({
          ...prevState,
          data: response.data.data,
        }));
      })

      .catch(notifyError)

      .finally(() => {
        setPaymentMethods((prevState) => ({ ...prevState, loading: false }));
      });
  }, []);

  useEffect(() => {
    if (formData.CODCOLIGADA && formData.IDHABILITACAOFILIAL) {
      setOfferPrice((prevState) => ({ ...prevState, loading: true }));

      const offerId = `${formData.CODCOLIGADA}| |${formData.IDHABILITACAOFILIAL}`;
      offerService
        .getOfferPrice(offerId)
        .then((response) => {
          setOfferPrice((prevState) => ({
            ...prevState,
            data: response.data.data,
          }));

          updateFormData("VALOR_CURSOBRUTO", response.data.data.PRECO);
          updateFormData("VALOR_CURSO", response.data.data.PRECO);
          updateFormData("VALOR_TAXA", response.data.data.TAXA);
        })

        .catch(notifyError)

        .finally(() => {
          setOfferPrice((prevState) => ({ ...prevState, loading: false }));
        });
    }
  }, [formData.CODCOLIGADA, formData.IDHABILITACAOFILIAL]);

  const updateFormData = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  useEffect(() => {
    setIsLoadingRecord(true);
    recordService
      .getRecordById(codRecord)
      .then((response) => {
        setFormData(response.data.data)

        

      })
      .catch(notifyError)
      .finally(() => setIsLoadingRecord(false));
  }, [codRecord]);

  
  const handleSubmit = () => {
    setIsSaving(true);
    recordService
      .updateRecord(codRecord, formData)
      .then(() => nextStep())
      .catch(notifyError)
      .finally(() => setIsSaving(false));
  };

  const [isGeneratingInstallments, setIsGeneratingInstallments] =
    useState(false);
  const [installments, setInstallments] = useState({});

  const generateInstallments = () => {
    setIsSaving(true);
    recordService
      .updateRecord(codRecord, formData)
      .finally(() => setIsSaving(false));

    setIsGeneratingInstallments(true);
    offerService
      .generateInstallments(
        `${formData.CODCOLIGADA}||${formData.IDHABILITACAOFILIAL}`,
        formData.FORMAPAG,
        "P",
        codRecord
      )
      .then((response) => setInstallments(response.data))
      .finally(() => {
        setIsGeneratingInstallments(false);
      });
  };

  useEffect(() => {
    console.log({ installments });
  }, [installments]);

  const installmentsTableFields = [
    {
      key: "PARCELA",
      title: "Parcela",
    },
    {
      key: "VALORORIGINAL",
      title: "Valor",
      format: formatMoney,
    },
    {
      key: "DTVENCIMENTO",
      title: "Data de Vencimento",
      format: formatDate,
    },
  ];

  return (
    <>
      <form
        className="d-flex flex-column flex-grow-1"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="row">
          <h6>Pagamento</h6>
          <SelectInputComponent
            label="Forma de Pagamento"
            placeholder={
              paymentMethods.loading || isLoadingRecord
                ? "Carregando..."
                : "Forma de Pagamento"
            }
            options={
              paymentMethods.data &&
              paymentMethods.data.map((item) => ({
                ...item,
                key: item["FORMAPAG"],
                label: item["DESCRICAO"] + " - " + item["TIPODOC"],
              }))
            }
            value={formData.FORMAPAG || ""}
            onSelect={(option) => {
              updateFormData("FORMAPAG", option.FORMAPAG);
              updateFormData("TAXA_REAJUSTE", formatNumber(option.ACRESCIMO));
            }}
            disabled={
              !paymentMethods.data || paymentMethods.loading || isLoadingRecord
            }
            className="col-12 col-md-4"
          />
          <TextInputComponent
            label="Taxa Reajuste"
            placeholder=""
            disabled={true}
            className="col-12 col-md-4"
            value={
              formData.TAXA_REAJUSTE
                ? formatMoney(formData.TAXA_REAJUSTE)
                : "Selecione uma forma de pagamento"
            }
          />
          <TextInputComponent
            label="Preço"
            placeholder=""
            className="col-12 col-md-4"
            disabled={true}
            value={formatMoney(offerPrice.data ? offerPrice.data.PRECO : "")}
          />

          <TextInputComponent
            label="Desconto"
            placeholder=""
            className="col-12 col-md-4"
          />

          <TextInputComponent
            label="Preço com Desconto"
            placeholder=""
            className="col-12 col-md-4"
          />

          <DateInputComponent
            label="Data de Vencimento"
            placeholder={isLoadingRecord ? "Carregando..." : ""}
            className="col-12 col-md-4"
            dataKey="DATAINICVENC" // Adiciona o dataKey para identificação no componente
            value={formData.DATAINICVENC || getNextMonthDate()}
            onChange={(key, date) => updateFormData(key, date)} // Passa a chave e o valor para a função de atualização
            disabled={isLoadingRecord}
          />
        </section>

        <section>
          {Array.isArray(installments.data) ? (
            <TableComponent
              data={installments.data}
              fields={installmentsTableFields}
            />
          ) : (
            ""
          )}
        </section>

        <div className="flex-grow-1 d-flex justify-content-end  align-items-end">
          <PreviousButtonComponent onClick={previousStep} />
          <GenericButtonComponent
            onClick={() => {}}
            loading={false}
            title="Usar token"
            className="btn-primary"
          />
          <GenericButtonComponent
            onClick={generateInstallments}
            loading={isGeneratingInstallments}
            title="Simular parcelamento"
            className="btn-success"
          />
        </div>
      </form>
    </>
  );
};

export default Step5;
